import {reducer} from './../../app/modules/auth/redux/AuthRedux'
import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as client from '../../app/component/client'
import * as dashboard from '../../app/component/dashboard'
import * as vendor from '../../app/component/vendor'
import * as certificate from '../../app/component/certificate'
import * as certificateorder from '../../app/component/certificateorder'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  client: client.reducer,
  dashboard: dashboard.reducer,
  vendor: vendor.reducer,
  certificate: certificate.reducer,
  certificateorder: certificateorder.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    client.saga(),
    dashboard.saga(),
    vendor.saga(),
    certificate.saga(),
    certificateorder.saga(),
  ])
}
