import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  SetUser: '[Set User] Action',
  SetLoader: 'SET_LOADER',
  SetRole: 'SET_ROLE',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  isLoading: false,
  role: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  isLoading?: boolean
  role?: string
}

export const reducer = persistReducer(
  {storage, key: 'auth', whitelist: ['user', 'accessToken', 'role']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        console.log(accessToken)
        return {accessToken, user: user}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetLoader: {
        const isLoading = action.payload?.isLoading
        return {...state, isLoading}
      }

      case actionTypes.SetRole: {
        const role = action.payload?.role
        return {...state, role}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, user: any) => ({
    type: actionTypes.Login,
    payload: {accessToken, user},
  }),
  logout: () => ({type: actionTypes.Logout}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  setLoading: (isLoading: boolean) => ({type: actionTypes.SetLoader, payload: {isLoading}}),
  setRole: (role: string) => ({type: actionTypes.SetRole, payload: {role}}),
}

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser())
  // })
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
