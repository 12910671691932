import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setCertificateData: 'SET_CERTIFICATE_DATA',
  setCertificateDataTotal: 'SET_CERTIFICATE_DATA_TOTAL',
  setCertificateLoading: 'SET_CERTIFICATE_LOADER',
  setCertificateId: 'SET_CERTIFICATE_ID',
}

const initialClientState: ICertificateState = {
  certificateData: undefined,
  certificateDataTotal: undefined,
  certificateLoading: false,
  certificateId: undefined,
}

export interface ICertificateState {
  certificateData?: any
  certificateDataTotal?: number
  certificateLoading?: boolean
  certificateId?: string
}

export const reducer = persistReducer(
  {
    storage,
    key: 'certificate',
    whitelist: ['certificateData', 'certificateDataTotal', 'certificateId'],
  },
  (state: ICertificateState = initialClientState, action: ActionWithPayload<ICertificateState>) => {
    switch (action.type) {
      case actionTypes.setCertificateData: {
        const certificateData = action.payload?.certificateData
        return {...state, certificateData}
      }

      case actionTypes.setCertificateDataTotal: {
        const certificateDataTotal = action.payload?.certificateDataTotal
        return {...state, certificateDataTotal}
      }

      case actionTypes.setCertificateLoading: {
        const certificateLoading = action.payload?.certificateLoading
        return {...state, certificateLoading}
      }

      case actionTypes.setCertificateId: {
        const certificateId = action.payload?.certificateId
        return {...state, certificateId}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setCertificateData: (certificateData: Array<any>) => ({
    type: actionTypes.setCertificateData,
    payload: {certificateData: certificateData},
  }),
  setCertificateDataTotal: (certificateDataTotal: number) => ({
    type: actionTypes.setCertificateDataTotal,
    payload: {certificateDataTotal},
  }),
  setCertificateLoading: (certificateLoading: boolean) => ({
    type: actionTypes.setCertificateLoading,
    payload: {certificateLoading},
  }),
  setCertificateId: (certificateId: string) => ({
    type: actionTypes.setCertificateId,
    payload: {certificateId},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setCertificateData, function* loginSaga() {
    yield put(actions.setCertificateLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
