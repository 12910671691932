import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setCertificateOrderData: 'SET_CERTIFICATE_ORDER_DATA',
  setCertificateOrderDataTotal: 'SET_CERTIFICATE_ORDER_DATA_TOTAL',
  setCertificateOrderLoading: 'SET_CERTIFICATE_ORDER_LOADER',
  setCertificateOrderViewData: 'SET_CERTIFICATE_ORDER_VIEW_DATA',
}

const initialClientState: ICertificateState = {
  certificateOrderData: undefined,
  certificateOrderDataTotal: undefined,
  certificateOrderLoading: false,
  certificateOrderViewData: undefined,
}

export interface ICertificateState {
  certificateOrderData?: any
  certificateOrderDataTotal?: number
  certificateOrderLoading?: boolean
  certificateOrderViewData?: any
}

export const reducer = persistReducer(
  {
    storage,
    key: 'certificateorder',
    whitelist: ['certificateOrderData', 'certificateOrderDataTotal', 'certificateOrderViewData'],
  },
  (state: ICertificateState = initialClientState, action: ActionWithPayload<ICertificateState>) => {
    switch (action.type) {
      case actionTypes.setCertificateOrderData: {
        const certificateOrderData = action.payload?.certificateOrderData
        return {...state, certificateOrderData}
      }

      case actionTypes.setCertificateOrderDataTotal: {
        const certificateOrderDataTotal = action.payload?.certificateOrderDataTotal
        return {...state, certificateOrderDataTotal}
      }

      case actionTypes.setCertificateOrderLoading: {
        const certificateOrderLoading = action.payload?.certificateOrderLoading
        return {...state, certificateOrderLoading}
      }

      case actionTypes.setCertificateOrderViewData: {
        const certificateOrderViewData = action.payload?.certificateOrderViewData
        return {...state, certificateOrderViewData}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setCertificateOrderData: (certificateOrderData: Array<any>) => ({
    type: actionTypes.setCertificateOrderData,
    payload: {certificateOrderData: certificateOrderData},
  }),
  setCertificateOrderDataTotal: (certificateOrderDataTotal: number) => ({
    type: actionTypes.setCertificateOrderDataTotal,
    payload: {certificateOrderDataTotal},
  }),
  setCertificateOrderLoading: (certificateOrderLoading: boolean) => ({
    type: actionTypes.setCertificateOrderLoading,
    payload: {certificateOrderLoading},
  }),
  setCertificateOrderViewData: (certificateOrderViewData: Array<any>) => ({
    type: actionTypes.setCertificateOrderViewData,
    payload: {certificateOrderViewData: certificateOrderViewData},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setCertificateOrderData, function* loginSaga() {
    yield put(actions.setCertificateOrderLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
