import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setVendorData: 'SET_VENDOR_DATA',
  setVendorDataTotal: 'SET_VENDOR_DATA_TOTAL',
  setVendorLoading: 'SET_VENDOR_LOADER',
}

const initialClientState: IClientState = {
  vendorData: undefined,
  vendorDataTotal: undefined,
  vendorLoading: false,
}

export interface IClientState {
  vendorData?: any
  vendorDataTotal?: number
  vendorLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'vendor', whitelist: ['vendorData', 'vendorDataTotal']},
  (state: IClientState = initialClientState, action: ActionWithPayload<IClientState>) => {
    switch (action.type) {
      case actionTypes.setVendorData: {
        const vendorData = action.payload?.vendorData
        return {...state, vendorData}
      }

      case actionTypes.setVendorDataTotal: {
        const vendorDataTotal = action.payload?.vendorDataTotal
        return {...state, vendorDataTotal}
      }

      case actionTypes.setVendorLoading: {
        const vendorLoading = action.payload?.vendorLoading
        return {...state, vendorLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setVendorData: (vendorData: Array<any>) => ({
    type: actionTypes.setVendorData,
    payload: {vendorData: vendorData},
  }),
  setVendorDataTotal: (vendorDataTotal: number) => ({
    type: actionTypes.setVendorDataTotal,
    payload: {vendorDataTotal},
  }),
  setVendorLoading: (vendorLoading: boolean) => ({
    type: actionTypes.setVendorLoading,
    payload: {vendorLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setVendorData, function* loginSaga() {
    yield put(actions.setVendorLoading(false))
  })

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser())
  // })

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {data: user} = yield getUserByToken()
  //   yield put(actions.fulfillUser(user))
  // })
}
