import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import { InputFileUploadWithCrop } from '../../../modules/comman/formComponents/InputFileUploadWithCrop'

interface IRenderFormikForm {
  values: ICertificateFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface ICertificateFormValues {
  shape_cut: string
  // weight: any
  gross_weight: any
  diamond_weight: any
  color: string
  clarity: string
  description: string
  // comments: string
  image_name: string
  category: string
  code: string
  dia: string
  purity: string
}

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'https://igl.api.dharmatech.in/'

export class EditCertificateForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: ICertificateFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.shape_cut) {
      errors.shape_cut = 'Shape Cut is required'
    }

    // if (!values.weight) {
    //   errors.weight = 'Weight is required'
    // }

    // if (!values.gross_weight) {
    //   errors.gross_weight = 'Gross Weight is required'
    // }

    // if (!values.diamond_weight) {
    //   errors.diamond_weight = 'Diamond Weight is required'
    // }

    if (!values.color) {
      errors.color = 'Color is required'
    }

    if (!values.clarity) {
      errors.clarity = 'Clarity is required'
    }

    if (!values.description) {
      errors.description = 'Description is required'
    }

    // if (!values.comments) {
    //   errors.comments = 'Comments is required'
    // }
    if (!values.category) {
      errors.category = 'Category is required'
    }
    // if (!values.code) {
    //   errors.code = 'Code is required'
    // }
    if (!values.dia) {
      errors.dia = 'Dia is required'
    }
    if (!values.purity) {
      errors.purity = 'Purity is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  componentDidMount(): void {
    if (this.props.data) {
      this.setState({
        image_url: this.props.data.image_url,
      })
      this.setState({
        file_name: this.props.data.image_name,
      })
    }
  }

  public handleSubmit = (values: ICertificateFormValues, action: any) => {
    if (this.state.file_name) {
      values.image_name = this.state.file_name
    } else {
      Toaster({
        type: 'error',
        title: 'Certificate',
        description: 'Please Upload Certificate Image',
      })
      return
    }
    console.log('Basic Value - ', values)
    this.props.onSubmit(values)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isImageLinked: false,
      image_url: `${REACT_APP_API_URL}certificate/${fileName}`,
      file_name: fileName,
    })
  }

  updateDescription = (setFieldValue: any, values: any) => {
    const { purity, category, gross_weight } = values;
    const description = `${purity} ${category}, Weight in total ${gross_weight}`;
    setFieldValue('description', description);
  };
  
  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Create Certificate Form</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  shape_cut: this.props.data.shape_cut ? this.props.data.shape_cut : '',
                  // weight: this.props.data.weight ? this.props.data.weight : '',
                  gross_weight: this.props.data.gross_weight ? this.props.data.gross_weight : '',
                  diamond_weight: this.props.data.diamond_weight ? this.props.data.diamond_weight : '',
                  color: this.props.data.color ? this.props.data.color : '',
                  clarity: this.props.data.clarity ? this.props.data.clarity : '',
                  description: this.props.data.description ? this.props.data.description : '',
                  // comments: this.props.data.comments ? this.props.data.comments : '',
                  image_name: this.props.data.image_name ? this.props.data.image_name : '',
                  certificate_id: this.props.data._id ? this.props.data._id : '',
                  category: this.props.data.category ? this.props.data.category : '',
                  code: this.props.data.code ? this.props.data.code : '',
                  dia: this.props.data.dia ? this.props.data.dia : '',
                  purity: this.props.data.purity ? this.props.data.purity : '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.shape_cut,
                              id: 'shape_cut',
                              name: 'shape_cut',
                            }}
                            placeholder='Enter Shape Cut'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('shape_cut', value)
                            }}
                            onBlur={handleBlur}
                            label='Shape Cut'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.weight,
                              id: 'weight',
                              name: 'weight',
                            }}
                            placeholder='Enter Weight'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('weight', value)
                              this.updateDescription(setFieldValue, {
                                ...values,
                                category: value,
                              });
                            }}
                            onBlur={handleBlur}
                            label=' Weight'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                              <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.gross_weight,
                              id: 'gross_weight',
                              name: 'gross_weight',
                            }}
                            placeholder='Enter Gross Weight'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('gross_weight', value)
                              this.updateDescription(setFieldValue, {
                                ...values,
                                gross_weight: value,
                              })
                            }}
                            onBlur={handleBlur}
                            label=' Gross Weight'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.diamond_weight,
                              id: 'diamond_weight',
                              name: 'diamond_weight',
                            }}
                            placeholder='Enter Diamond Weight'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('diamond_weight', value)
                            }}
                            onBlur={handleBlur}
                            label=' Diamond Weight'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.dia,
                              id: 'dia',
                              name: 'dia',
                            }}
                            placeholder='Enter Dia'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('dia', value)
                            }}
                            onBlur={handleBlur}
                            label='Dia'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.color,
                              id: 'color',
                              name: 'color',
                            }}
                            placeholder='Enter Color'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('color', value)
                            }}
                            onBlur={handleBlur}
                            label='Color'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.clarity,
                              id: 'clarity',
                              name: 'clarity',
                            }}
                            placeholder='Enter Clarity'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('clarity', value)
                            }}
                            onBlur={handleBlur}
                            label='Clarity'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.purity,
                              id: 'purity',
                              name: 'purity',
                            }}
                            placeholder='Enter Purity'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('purity', value)
                              this.updateDescription(setFieldValue, {
                                ...values,
                                purity: value,
                              });
                            }}
                            onBlur={handleBlur}
                            label='Purity'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.category,
                              id: 'category',
                              name: 'category',
                            }}
                            placeholder='Enter Category'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('category', value)
                              this.updateDescription(setFieldValue, {
                                ...values,
                                category: value,
                              });
                            }}
                            onBlur={handleBlur}
                            label='Category'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.code,
                              id: 'code',
                              name: 'code',
                            }}
                            placeholder='Enter Code'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('code', value)
                            }}
                            onBlur={handleBlur}
                            label='Code'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.description,
                              id: 'description',
                              name: 'description',
                            }}
                            placeholder='Enter Description'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('description', value)
                            }}
                            onBlur={handleBlur}
                            label='Description'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.dia,
                              id: 'dia',
                              name: 'dia',
                            }}
                            placeholder='Enter Dia'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('dia', value)
                            }}
                            onBlur={handleBlur}
                            label='Dia'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                      </div>
                 
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.comments,
                              id: 'comments',
                              name: 'comments',
                            }}
                            placeholder='Enter Comments'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('comments', value)
                            }}
                            onBlur={handleBlur}
                            label='Comments'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}

                      <div>
                        <InputFileUploadWithCrop
                          ref={(e: any) => {
                            this.viewFileUploadModalRef = e
                          }}
                          //   buttonLabel={'Choose Provider Image'}
                          onBlur={() => {}}
                          error={() => {}}
                          touched={() => {}}
                          //   required={false}
                          //   label='Item Image'
                          name='image_url'
                          input={{
                            id: 'image',
                            name: 'image',
                            action: `${REACT_APP_API_URL}file/upload/certificate/image`,
                            headers: {
                              authorization: 'authorized-text',
                            },
                            accept: 'png|jpeg|jpg|gif|webp',
                            acceptMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
                            mediaType: 'png|jpeg|jpg|gif|webp',
                            mediaModuleType: 'png|jpeg|jpg|gif|webp',
                          }}
                          onUpload={(
                            fileName: string,
                            FileData: Array<any>,
                            customFileData: Object
                          ) => {
                            this.uploadMedia(fileName, FileData, customFileData)
                          }}
                        />
                      </div>

                      {console.log(this.state.image_url)}
                      {this.state.image_url ? (
                        <img
                          src={`${this.state.image_url}`}
                          alt='Certificate'
                          style={{width: '200px', height: '200px', margin: '10px auto'}}
                        />
                      ) : null}
                      <div style={{marginBottom: '15px'}}></div>

                      <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
