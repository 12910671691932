import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_CERTIFICATE_ORDER_URL = `${API_URL}v1/auth/both/certificate-orders`
export const SEARCH_CERTIFICATE_ORDER_URL = `${API_URL}v1/auth/both/certificate-orders`
export const CERTIFICATE_ORDER_SHIPMENT_URL = `${API_URL}v1/auth/admin/certificates/shipment-status`
export const CERTIFICATE_ORDER_SHIPMENT_STATUS = `${API_URL}v1/auth/admin/certificate/order/shipment-status`
export const CERTIFICATE_SINGLE_SHIPMENT_STATUS = `${API_URL}v1/auth/admin/certificate/shipment-status`

// Get Data
export async function getCertificateOrderData(startIndex: number, limitNumber: number) {
  try {
    let certificateData = await axios.get(
      `${GET_CERTIFICATE_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}`
    )
    return certificateData
  } catch (e) {
    throw e
  }
}

// Search Data
export async function getSearchCertificateOrderData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let certificateData = await axios.get(
      `${SEARCH_CERTIFICATE_ORDER_URL}?skip=${startIndex}&limit=${limitNumber}&searchText=${searchText}`
    )
    return certificateData
  } catch (e) {
    throw e
  }
}

export async function editCertificateOrderShipmentData(status: any, id: any) {
  try {
    let editShipmentData = await axios.put(`${CERTIFICATE_ORDER_SHIPMENT_URL}`, {
      order_id: id,
      status: 'shipped',
    })
    return editShipmentData
  } catch (e) {
    throw e
  }
}

export async function changeStatusForOrderCertificate(id: any) {
  try {
    let editShipmentData = await axios.put(`${CERTIFICATE_ORDER_SHIPMENT_STATUS}`, {
      order_id: id,
      status: 'shipped',
    })
    return editShipmentData
  } catch (e) {
    throw e
  }
}

export async function changeStatusForSingleCertificate(id: any) {
  try {
    let editShipmentData = await axios.put(`${CERTIFICATE_SINGLE_SHIPMENT_STATUS}`, {
      certificate_id: id,
      status: 'shipped',
    })
    return editShipmentData
  } catch (e) {
    throw e
  }
}
