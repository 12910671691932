import * as React from 'react'
import {Formik} from 'formik'
import {Button} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Spin} from 'antd'
// import readXlsxFile from 'read-excel-file'
import {createCertificateBulkUploadData} from '../redux/CertificateCRUD'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'

const API_URL = process.env.REACT_APP_API_URL
interface IRenderFormikForm {
  values: IBulkFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IBulkFormValues {
  excel: any
}

interface IEditClientProps {
  data?: any
  handleCancel?: any
  authToken: string
}

let size: any
let valuesData: any
let WrongValuesArray: any = []

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class BulkUploadCertificate extends React.Component<IEditClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: null,
      progress: 0,
      url: '',
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IBulkFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)
    if (!values.excel) {
      errors.excel = 'Excel is Required'
    }
    console.log('Validating errors -- ', errors)
    size = Object.keys(errors).length
    return errors
  }

  public handleSubmit = (values: IBulkFormValues, action: any) => {}

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public formHandler = async (e: any) => {
    try {
      e.preventDefault()
      const file = e.target[0].files[0]
      console.log(file)
      if (file && file.name) {
        let bulkFileUploadData = await createCertificateBulkUploadData(file)
        Toaster({
          type: 'success',
          title: 'Bulk Certificate',
          description: 'Uploaded SuccessFully',
        })
        this.props.handleCancel()
      } else {
        Toaster({
          type: 'error',
          title: 'Item',
          description: 'No Excel Found',
        })
        return
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.props.handleCancel()
    }
  }

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isImageLinked: false,
      //   image_url: `${REACT_APP_API_URL}certificate/image/${fileName}`,
      file_name: fileName,
    })
  }

  public showMediaModal = (params: any) => {
    this.setState(
      {
        viewFileUploadModal: true,
      },
      () => {
        this.viewFileUploadModalRef?.show?.()
      }
    )
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          {this.props.data && !this.state.isLoading ? (
            <div className='card-body'>
              <div>
                <Formik
                  initialValues={{
                    excel: '',
                  }}
                  validate={this.validateForm}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                  }: IRenderFormikForm) => {
                    return (
                      <div className='table-form'>
                        <div className='form-group row'>
                          {/* <div className='col-md-12 col-lg-12 col-sm-12'>
                            <form
                              onSubmit={(e: any) => {
                                this.formHandler(e)
                              }}
                            >
                              <input
                                type='file'
                                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                              ></input>
                              <button
                                type='submit'
                                style={{
                                  backgroundColor: '#1890ff',
                                  color: 'white',
                                  borderRadius: '6px',
                                  borderColor: 'white',
                                  padding: '5px',
                                }}
                              >
                                Upload
                              </button>
                            </form>
                          </div> */}
                          <InputSingleFileUpload
                            ref={(e: any) => {
                              this.viewFileUploadModalRef = e
                            }}
                            //   buttonLabel={'Choose Provider Image'}
                            onBlur={() => {}}
                            error={() => {}}
                            touched={() => {}}
                            //   required={false}
                            //   label='Item Image'
                            name='bulk'
                            input={{
                              id: 'file',
                              name: 'file',
                              action: `${REACT_APP_API_URL}auth/upload/certificates`,
                              headers: {
                                authorization: `Bearer ${this.props.authToken}`,
                              },
                              accept: 'csv|xlxs',
                              acceptMimeTypes: [
                                'text/csv',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-excel',
                              ],
                              mediaType: 'csv|xlxs',
                              mediaModuleType: 'csv|xlxs',
                            }}
                            onUpload={(
                              fileName: string,
                              FileData: Array<any>,
                              customFileData: Object
                            ) => {
                              this.uploadMedia(fileName, FileData, customFileData)
                            }}
                          />
                          <div className='col-md-12 col-lg-12 col-sm-12' style={{color: '#f1416c'}}>
                            {errors.excel}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-md-5 col-lg-5 col-sm-5'>
                            <Button
                              type='primary'
                              disabled={size}
                              style={{marginTop: '15px', float: 'left', borderRadius: '6px'}}
                              onClick={(e: any) => {
                                e.preventDefault()
                                window.open(`https://api.igl-india.com/certificate/sample.csv`, '_blank')
                              }}
                            >
                              Download Sample Excel
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </Formik>
              </div>
            </div>
          ) : (
            <Spin />
          )}
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
