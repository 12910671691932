import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_VENDOR_URL = `${API_URL}v1/auth/admin/vendors/`
export const SEARCH_VENDOR_URL = `${API_URL}v1/auth/admin/vendors/`
export const CREATE_VENDOR_URL = `${API_URL}v1/auth/admin/vendor/`
export const EDIT_VENDOR_URL = `${API_URL}v1/auth/admin/vendor/`
export const DELETE_VENDOR_URL = `${API_URL}v1/auth/admin/vendor/`
export const CHANGE_STATUS_VENDOR_URL = `${API_URL}v1/auth/vendor/active-status/`
export const PAYMENT_STATUS_VENDOR_URL = `${API_URL}v1/auth/vendor/certificate-order`

// Get Client Data
export async function getVendorData(startIndex: number, limitNumber: number) {
  try {
    let vendorData = await axios.get(`${GET_VENDOR_URL}${startIndex}/${limitNumber}`)
    return vendorData
  } catch (e) {
    throw e
  }
}

// Search Client Data
export async function getSearchVendorData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let vendorData = await axios.get(
      `${SEARCH_VENDOR_URL}${startIndex}/${limitNumber}&searchText=${searchText}`
    )
    return vendorData
  } catch (e) {
    throw e
  }
}

// Create Client Data
export async function createVendorData(vendorObject: any) {
  try {
    let createVendorData = await axios.post(`${CREATE_VENDOR_URL}`, {
      ...vendorObject,
    })
    return createVendorData
  } catch (e) {
    throw e
  }
}

// Delete Client Data
export async function deleteVendorData(vendor_id: string) {
  try {
    let deleteVendorData = await axios.delete(`${DELETE_VENDOR_URL}?vendor_id=${vendor_id}`)
    return deleteVendorData
  } catch (e) {
    throw e
  }
}

// Change Status Client Data
export async function changeStatusVendorData(vendorId: string, flag: boolean) {
  try {
    let vendorData = await axios.put(`${CHANGE_STATUS_VENDOR_URL}`, {
      vendorId,
      flag,
    })
    return vendorData
  } catch (e) {
    throw e
  }
}

// Edit Client Data
export async function editVendorData(vendorObject: any) {
  try {
    let editVendorData = await axios.put(`${EDIT_VENDOR_URL}`, {
      ...vendorObject,
    })
    return editVendorData
  } catch (e) {
    throw e
  }
}

export async function vendorPaymentData(id: any) {
  try {
    let editVendorData = await axios.post(`${PAYMENT_STATUS_VENDOR_URL}`, {
      order_id: id,
    })
    return editVendorData
  } catch (e) {
    throw e
  }
}
