/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CertificatePage} from '../../component/certificate'

const CertificateWrapper: FC = () => {
  return (
    <div>
      <CertificatePage />
    </div>
  )
}

export {CertificateWrapper}
