/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Table} from 'antd'
import React from 'react'

type Props = {
  onChange: (pagination: any, filters: any) => void
  size?: string
  rowKey: any
  column: Array<{
    title: string
    width?: number
    key: string
    dataIndex?: string
    fixed?: boolean
    render: (text: string, record: any, index: number) => void
  }>
  // pagination: Array<{
  //   total?: number
  //   showSizeChanger: boolean
  //   pageSize: number
  //   current: number
  // }>
  paginationConfig: {
    total?: number
    showSizeChanger: boolean
    pageSize: number
    current: number
  }
  isLoading: boolean
  dataSource: any
  counter: number
  tempCounter: number
  isScroll?: boolean
  scrollWidth?: number
}

const ListTable: React.FC<Props> = ({
  onChange,
  size = 'small',
  rowKey,
  column,
  paginationConfig,
  isLoading = false,
  dataSource,
  counter,
  tempCounter,
  isScroll,
  scrollWidth,
}) => {
  return (
    <>
      <Table
        loading={isLoading}
        rowKey={rowKey}
        columns={column}
        size={size}
        scroll={isScroll ? {x: scrollWidth} : null}
        dataSource={dataSource}
        pagination={{
          ...paginationConfig,
          pageSizeOptions: ['1', '10', '25', '50', '100'],
          onChange: (e: any) => {
            {
              if (e - 1) {
                counter = tempCounter = (e - 1) * paginationConfig.pageSize
                return
              }
              counter = 0
              tempCounter = 0
            }
          },
        }}
        onChange={onChange}
      />
    </>
  )
}

export {ListTable}
