import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_CERTIFICATE_URL = `${API_URL}v1/auth/`
export const SEARCH_CERTIFICATE_URL = `${API_URL}v1/auth/`
export const CREATE_CERTIFICATE_URL = `${API_URL}v1/auth/`
export const EDIT_CERTIFICATE_URL = `${API_URL}v1/auth/`
export const DELETE_CERTIFICATE_URL = `${API_URL}v1/auth/`
export const CHANGE_STATUS_CERTIFICATE_URL = `${API_URL}v1/auth/certificate/active-status/`
export const VIEW_CERTIFICATE_URL = `${API_URL}v1/auth/`
export const BULK_UPLOAD_CERTIFICATE_URL = `${API_URL}auth/upload/certificates`
export const BULK_UPLOAD_ZIP_IMAGE_URL = `${API_URL}v1/auth/both/certificate-images`

// Get Client Data
export async function getCertificateData(startIndex: number, limitNumber: number, role: any) {
  try {
    let certificateData = await axios.get(
      `${GET_CERTIFICATE_URL}${
        role == 'vendor' ? 'vendor' : 'admin'
      }/certificates?skip=${startIndex}&limit=${limitNumber}`
    )
    return certificateData
  } catch (e) {
    throw e
  }
}

// Search Client Data
export async function getSearchCertificateData(
  startIndex: number,
  limitNumber: number,
  searchText: string,
  role: any
) {
  try {
    let certificateData = await axios.get(
      `${SEARCH_CERTIFICATE_URL}${
        role == 'vendor' ? 'vendor' : 'admin'
      }/certificates?skip=${startIndex}&limit=${limitNumber}&searchText=${searchText}`
    )
    return certificateData
  } catch (e) {
    throw e
  }
}

export async function getSingleCertificateData(certificate_id: string, role: any) {
  try {
    let certificateData = await axios.get(
      `${VIEW_CERTIFICATE_URL}${
        role == 'vendor' ? 'vendor' : 'admin'
      }/certificate?certificate_id=${certificate_id}`
    )
    return certificateData
  } catch (e) {
    throw e
  }
}

// Create Client Data
export async function createCertificateData(certificateObject: any, role: any) {
  try {
    let createCertificateData = await axios.post(
      `${CREATE_CERTIFICATE_URL}${role == 'vendor' ? 'vendor' : 'admin'}/certificate`,
      {
        ...certificateObject,
      }
    )
    return createCertificateData
  } catch (e) {
    throw e
  }
}

// Delete Client Data
export async function deleteCertificateData(certificate_id: string, role: any) {
  try {
    let deleteCertificateData = await axios.delete(
      `${DELETE_CERTIFICATE_URL}${
        role == 'vendor' ? 'vendor' : 'admin'
      }/certificate?certificate_id=${certificate_id}`
    )
    return deleteCertificateData
  } catch (e) {
    throw e
  }
}

// Change Status Client Data
export async function changeStatusCertificateData(certificateId: string, flag: boolean, role: any) {
  try {
    let certificateData = await axios.put(`${CHANGE_STATUS_CERTIFICATE_URL}`, {
      certificateId,
      flag,
    })
    return certificateData
  } catch (e) {
    throw e
  }
}

// Edit Client Data
export async function editCertificateData(certificateObject: any, role: any) {
  try {
    let editCertificateData = await axios.put(
      `${EDIT_CERTIFICATE_URL}${role == 'vendor' ? 'vendor' : 'admin'}/certificate`,
      {
        ...certificateObject,
      }
    )
    return editCertificateData
  } catch (e) {
    throw e
  }
}

export async function createCertificateBulkUploadData(file: any) {
  try {
    console.log(file)
    let editCertificateData = await axios.post(`${BULK_UPLOAD_CERTIFICATE_URL}`, {
      file,
    })
    return editCertificateData
  } catch (e) {
    throw e
  }
}

export async function zipBulkImageUpload(file_name: any) {
  try {
    let imageZipUploadData = await axios.post(`${BULK_UPLOAD_ZIP_IMAGE_URL}`, {
      file_name,
    })
    return imageZipUploadData
  } catch (e) {
    throw e
  }
}
