/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as certificateorder from '../redux/CertificateOrderRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EyeOutlined, DollarOutlined, EditOutlined, CarOutlined} from '@ant-design/icons'
import {
  changeStatusForOrderCertificate,
  getCertificateOrderData,
  getSearchCertificateOrderData,
} from '../redux/CertificateOrderCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {vendorPaymentData} from '../../vendor/redux/VendorCRUD'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  certificateOrderData: any
  certificateOrderDataTotal: number
  certificateOrderLoading: boolean
  role: any
  setListCertificateOrderData: (certificateOrderData: any) => void
  setListCertificateOrderViewData: (certificateOrderViewData: any) => void
  setListCertificateOrderDataTotal: (certificateOrderDataTotal: number) => void
  setCertificateOrderLoading: (certificateOrderLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  certificateSelectedData: any
  certificateViewData: any
}

type PathParamsType = {
  param1: string
}
class ListCertificateOrderData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 50,
      pagination: {
        pageSize: 50,
        current: 1,
      },
      searchText: '',
      certificateSelectedData: null,
      certificateViewData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListCertificateOrderData(pagination)
    )
  }

  public getListCertificateOrderData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listCertificateOrderSearchData(start, end)
        } else {
          this.listCertificateOrderData(start, end)
        }
      }
    )
  }

  public listCertificateOrderData = async (start: number, end: any) => {
    const {setCertificateOrderLoading} = this.props
    try {
      const {setListCertificateOrderData, setListCertificateOrderDataTotal} = this.props
      await setCertificateOrderLoading(true)
      let certificateOrderData = await getCertificateOrderData(start, end)
      if (certificateOrderData.data) {
        await setListCertificateOrderData(certificateOrderData.data.docs)
        await setListCertificateOrderDataTotal(certificateOrderData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateOrderLoading(false)
    }
  }

  public listCertificateOrderSearchData = async (start: number, end: any) => {
    const {setCertificateOrderLoading} = this.props
    try {
      const {setListCertificateOrderData, setListCertificateOrderDataTotal} = this.props
      const {searchText} = this.state

      await setCertificateOrderLoading(true)
      let certificateOrderData = await getSearchCertificateOrderData(start, end, searchText)
      if (certificateOrderData.data) {
        await setListCertificateOrderData(certificateOrderData.data.docs)
        await setListCertificateOrderDataTotal(certificateOrderData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateOrderLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listCertificateOrderSearchData(0, 50)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateVendor = () => {
    const {history} = this.props
    history.push('/order/create')
  }

  componentDidMount(): void {
    this.props.setListCertificateOrderViewData(undefined)
  }
  public deleteCertificate = async (item: any) => {
    const {setCertificateOrderLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setCertificateOrderLoading(true)
    //   await deleteCertificateData(item._id)

    //   Toaster({
    //     type: 'success',
    //     title: 'Certificate',
    //     description: 'Deleted SuccessFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listCertificateOrderSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listCertificateOrderData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setCertificateOrderLoading(false)
    // }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setCertificateOrderLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setCertificateOrderLoading(true)
    //   await changeStatusCertificateData(item._id, checked)

    //   Toaster({
    //     type: 'success',
    //     title: 'Certificate',
    //     description: 'Status changed successFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listCertificateOrderSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listCertificateOrderData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setCertificateOrderLoading(false)
    // }
  }

  public onEditSubmit = async (values: any) => {
    const {setCertificateOrderLoading} = this.props
    // try {
    //   const {startIndex, limitNumber, searchText} = this.state

    //   await setCertificateOrderLoading(true)
    //   await editCertificateData(values)

    //   Toaster({
    //     type: 'success',
    //     title: 'Certificate',
    //     description: 'Edited SuccessFully',
    //   })

    //   if (searchText != null && searchText !== '') {
    //     this.listCertificateOrderSearchData(startIndex, limitNumber)
    //     return
    //   }
    //   this.listCertificateOrderData(startIndex, limitNumber)
    // } catch (e) {
    //   console.log('!!!!Error', e)
    // } finally {
    //   await setCertificateOrderLoading(false)
    // }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      certificateSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      certificateSelectedData: null,
    })
  }

  public showViewModal = async (item: any) => {
    this.setState({
      certificateViewData: item,
    })
    this.props.setListCertificateOrderViewData(item)

    const {history} = this.props
    history.push('/order/view')
  }

  public closeViewModal = async () => {
    this.setState({
      certificateViewData: null,
    })
    this.listCertificateOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public paymentVendorApi = async (item: any) => {
    const {setCertificateOrderLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCertificateOrderLoading(true)
      await vendorPaymentData(item._id)

      Toaster({
        type: 'success',
        title: 'CERTIFICATE ORDER',
        description: 'Payment SuccessFull',
      })

      if (searchText != null && searchText !== '') {
        this.listCertificateOrderSearchData(startIndex, limitNumber)
        return
      }
      this.listCertificateOrderData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateOrderLoading(false)
    }
  }

  public changeOrderCertificateStatus = async (item: any) => {
    const {setCertificateOrderLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCertificateOrderLoading(true)
      await changeStatusForOrderCertificate(item._id)

      Toaster({
        type: 'success',
        title: 'CERTIFICATE ORDER',
        description: 'Shipment Status Changed SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listCertificateOrderSearchData(startIndex, limitNumber)
        return
      }
      this.listCertificateOrderData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateOrderLoading(false)
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    // {
    //   title: 'Certificate Number',
    //   key: 'Certificate Number',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <strong>
    //           {item.first_name
    //             ? item.first_name
    //             : '-'}
    //         </strong>
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Payment Status',
      key: 'payment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.payment_status && item.payment_status == 'completed' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Completed
              </h2>
            ) : item.payment_status && item.payment_status == 'failed' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Failed
              </h2>
            ) : (
              item.payment_status &&
              item.payment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Shipment Status',
      key: 'shipment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.shipment_status && item.shipment_status == 'approved' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Approved
              </h2>
            ) : item.shipment_status && item.shipment_status == 'rejected' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Rejected
              </h2>
            ) : (
              item.shipment_status &&
              item.shipment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Amount Paid',
      key: 'amount_paid',
      render: (text: any, item: any, index: number) => {
        return <div>{item.amount_paid ? item.amount_paid : 0}</div>
      },
    },
    // {
    //   title: 'isActive',
    //   key: 'isActive',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement='bottom' title='Vendor Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_active}
    //                 style={{
    //                   backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {this.props.role == 'vendor' && item.payment_status == 'pending' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Pay'>
                  <Popconfirm
                    onConfirm={() => {
                      //call delete vendor api
                      this.paymentVendorApi(item)
                    }}
                    title='Are you sure you want to pay?'
                  >
                    <Button shape='circle' icon={<DollarOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='View'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showViewModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public adminColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.vendor_id
                ? item.vendor_id.first_name && item.vendor_id.last_name
                  ? `${item.vendor_id.first_name} ${' '} ${item.vendor_id.last_name}`
                  : item.vendor_id.first_name
                  ? item.vendor_id.first_name
                  : '-'
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Payment Status',
      key: 'payment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.payment_status && item.payment_status == 'completed' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Completed
              </h2>
            ) : item.payment_status && item.payment_status == 'failed' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Failed
              </h2>
            ) : (
              item.payment_status &&
              item.payment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Shipment Status',
      key: 'shipment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.shipment_status && item.shipment_status == 'approved' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Approved
              </h2>
            ) : item.shipment_status && item.shipment_status == 'rejected' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Rejected
              </h2>
            ) : (
              item.shipment_status &&
              item.shipment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Total Certificates',
      key: 'certificate_numbers',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.certificate_numbers
              ? item.certificate_numbers.length
                ? item.certificate_numbers.length
                : 0
              : 0}
          </div>
        )
      },
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_amount ? item.total_amount : 0}</div>
      },
    },
    {
      title: 'Amount Paid',
      key: 'amount_paid',
      render: (text: any, item: any, index: number) => {
        return <div>{item.amount_paid ? item.amount_paid : 0}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {this.props.role == 'vendor' && item.payment_status == 'pending' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Pay'>
                  <Popconfirm
                    onConfirm={() => {
                      //call delete vendor api
                      this.paymentVendorApi(item)
                    }}
                    title='Are you sure you want to pay?'
                  >
                    <Button shape='circle' icon={<DollarOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='View'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showViewModal(item)
                  }}
                />
              </Tooltip>
            </div>
            {this.props.role == 'super admin' &&
              item.payment_status == 'completed' &&
              item.shipment_status == 'pending' && (
                <div style={{float: 'left', marginRight: '5px'}}>
                  <Tooltip placement='bottom' title='Confirm Shipment'>
                    <Popconfirm
                      onConfirm={() => {
                        //call delete vendor api
                        this.changeOrderCertificateStatus(item)
                      }}
                      title='Are you sure you want to change shipment status to completed?'
                    >
                      <Button shape='circle' icon={<CarOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              )}
          </div>
        )
      },
    },
  ]

  public vendorColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Payment Status',
      key: 'payment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.payment_status && item.payment_status == 'completed' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Completed
              </h2>
            ) : item.payment_status && item.payment_status == 'failed' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Failed
              </h2>
            ) : (
              item.payment_status &&
              item.payment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Shipment Status',
      key: 'shipment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.shipment_status && item.shipment_status == 'approved' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Approved
              </h2>
            ) : item.shipment_status && item.shipment_status == 'rejected' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Rejected
              </h2>
            ) : (
              item.shipment_status &&
              item.shipment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Total Certificates',
      key: 'certificate_numbers',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.certificate_numbers
              ? item.certificate_numbers.length
                ? item.certificate_numbers.length
                : 0
              : 0}
          </div>
        )
      },
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      render: (text: any, item: any, index: number) => {
        return <div>{item.total_amount ? item.total_amount : 0}</div>
      },
    },
    {
      title: 'Amount Paid',
      key: 'amount_paid',
      render: (text: any, item: any, index: number) => {
        return <div>{item.amount_paid ? item.amount_paid : 0}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {this.props.role == 'vendor' && item.payment_status == 'pending' && (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Pay'>
                  <Popconfirm
                    onConfirm={() => {
                      //call delete vendor api
                      this.paymentVendorApi(item)
                    }}
                    title='Are you sure you want to pay?'
                  >
                    <Button shape='circle' icon={<DollarOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            )}
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='View'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showViewModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {certificateOrderData, certificateOrderDataTotal, certificateOrderLoading} = this.props
    const {searchText, certificateSelectedData, certificateViewData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Certificate Order'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              {/* <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateVendor()
                  }}
                >
                  Add Certificate
                </InputButtonComponent>
              </div> */}
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.props.role == 'super admin' ? this.adminColumns : this.vendorColumns}
                  paginationConfig={{
                    total: certificateOrderDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={certificateOrderLoading}
                  dataSource={certificateOrderData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                  scrollWidth={1200}
                />
                <div>
                  <CommanModal
                    show={certificateSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Certificate'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {/* {certificateSelectedData && (
                        <EditCertificateForm
                          data={certificateSelectedData}
                          onSubmit={(values: any) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )} */}
                    </div>
                  </CommanModal>
                  {/* <CommanModal
                    show={certificateViewData ? true : false}
                    handleClose={() => {
                      this.closeViewModal()
                    }}
                    title={'View Certificate'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {/* {certificateViewData && (
                        <ViewCertificateData
                          data={certificateViewData}
                          // onSubmit={(values: any) => {
                          //   this.onEditSubmit(values)
                          // }}
                          handleCancel={() => {
                            this.closeViewModal()
                          }}
                        />
                      )} */}
                  {/* </div> */}
                  {/* </CommanModal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  certificateOrderData: state.certificateorder.certificateOrderData,
  certificateOrderDataTotal: state.certificateorder.certificateOrderDataTotal,
  certificateOrderLoading: state.certificateorder.certificateOrderLoading,
  role: state.auth.role,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListCertificateOrderData: (certificateOrderData: any) => {
      dispatch(certificateorder.actions.setCertificateOrderData(certificateOrderData))
    },
    setListCertificateOrderDataTotal: (certificateOrderDataTotal: number) => {
      dispatch(certificateorder.actions.setCertificateOrderDataTotal(certificateOrderDataTotal))
    },
    setCertificateOrderLoading: (certificateOrderLoading: boolean) => {
      dispatch(certificateorder.actions.setCertificateOrderLoading(certificateOrderLoading))
    },
    setListCertificateOrderViewData: (certificateOrderViewData: any) => {
      dispatch(certificateorder.actions.setCertificateOrderViewData(certificateOrderViewData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListCertificateOrderData))
