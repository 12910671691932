import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'

interface IRenderFormikForm {
  values: IVendorFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IVendorFormValues {
  first_name: string
  last_name: string
  email: string
  password: string
  company_name: string
  phone: string
}

interface IAddClientProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class EditVendorForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IVendorFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (!values.password) {
      errors.password = 'Password is required'
    }

    if (!values.company_name) {
      errors.company_name = 'Company name is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IVendorFormValues, action: any) => {
    console.log('Basic Value - ', values)
    this.props.onSubmit(values)
    this.props.handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          {/* <div className='card-header'>
            <h3 className='card-title'>Create Vendor Form</h3>
          </div> */}
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  first_name: this.props.data.first_name ? this.props.data.first_name : '',
                  last_name: this.props.data.last_name ? this.props.data.last_name : '',
                  email: this.props.data.email ? this.props.data.email : '',
                  password: this.props.data.password ? this.props.data.password : '',
                  company_name: this.props.data.company_name ? this.props.data.company_name : '',
                  phone: this.props.data.phone ? this.props.data.phone : '',
                  vendor_id: this.props.data._id ? this.props.data._id : '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.first_name,
                              id: 'first_name',
                              name: 'first_name',
                            }}
                            placeholder='Enter first name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('first_name', value)
                            }}
                            onBlur={handleBlur}
                            label='First Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.last_name,
                              id: 'last_name',
                              name: 'last_name',
                            }}
                            placeholder='Enter last name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('last_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Last Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.email,
                              id: 'email',
                              name: 'email',
                            }}
                            placeholder='Enter email'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('email', value)
                            }}
                            onBlur={handleBlur}
                            label='Email'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.company_name,
                              id: 'company_name',
                              name: 'company_name',
                            }}
                            placeholder='Enter company name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('company_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Company Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
