/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getVendorData} from '../redux/VendorCRUD'
import {useDispatch} from 'react-redux'
import * as vendor from '../redux/VendorRedux'
import ListVendorData from './ListVendorData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callVendorData = async () => {
    try {
      let vendorData = await getVendorData(0, 50)
      if (vendorData.data) {
        await dispatch(vendor.actions.setVendorLoading(true))
        // once vendor data set then loading will automatically off using saga middleware
        await dispatch(vendor.actions.setVendorData(vendorData.data.docs))
        await dispatch(vendor.actions.setVendorDataTotal(vendorData.data.totalDocs))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callVendorData()
  }, [])

  return (
    <div>
      <ListVendorData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/vendor/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/vendor/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.VENDOR'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
