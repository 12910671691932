/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createCertificateData, getCertificateData} from '../redux/CertificateCRUD'
import * as certificate from '../redux/CertificateRedux'
import {CreateCertificateForm} from './CreateCertificateForm'
import {ICertificateFormValues} from './CreateCertificateForm'
import {RootState} from '../../../../setup'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const role = useSelector<RootState>(({auth}) => auth.role, shallowEqual)

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callCertificateData = async () => {
    try {
      let certificateData = await getCertificateData(0, 10, role)
      if (certificateData.data) {
        await dispatch(certificate.actions.setCertificateLoading(true))
        // once certificate data set then loading will automatically off using saga middleware
        await dispatch(certificate.actions.setCertificateData(certificateData.data.docs))
        await dispatch(certificate.actions.setCertificateDataTotal(certificateData.data.total))
        history.push('/certificate/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: ICertificateFormValues) => {
    try {
      await createCertificateData(values, role)
      await callCertificateData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateCertificateForm onSubmit={(values: ICertificateFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'create',
            path: '/certificate/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'create',
            path: '/certificate/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CERTIFICATE'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
