import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {ClientWrapper} from '../pages/client/ClientWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {SettingWrapper} from '../pages/settings/SettingWrapper'
import {VendorWrapper} from '../pages/vendor/VendorWrapper'
import {CertificateWrapper} from '../pages/certificate/CertificateWrapper'
import {CertificateOrderWrapper} from '../pages/certificateorder/CertificateOrderWrapper'

export function PrivateRoutes(props: any) {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* For Dashboard */}
        <Route path='/dashboard' exact component={DashboardWrapper} />

        {/* For Client */}
        {/* <Route path='/client' component={ClientWrapper} /> */}

        {/* For Setting */}
        <Route path='/setting' component={SettingWrapper} />

        {/* For Vendor */}
        {props.adminData.role == 'super admin' && (
          <Route path='/vendor' component={VendorWrapper} />
        )}
        {/* For Certificate */}
        <Route path='/certificate' component={CertificateWrapper} />

        {/* For Certificate Order */}
        <Route path='/order' component={CertificateOrderWrapper} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
