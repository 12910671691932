import {FC, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as certificateorder from './CertificateOrderRedux'
import {RootState} from '../../../../setup'

const mapState = (state: RootState) => ({certificateorder: state.certificateorder})
const connector = connect(mapState, certificateorder.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const CertificateOrderInit: FC<PropsFromRedux> = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken before rendering the application
  useEffect(() => {}, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(CertificateOrderInit)
