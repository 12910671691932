/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getCertificateOrderData} from '../redux/CertificateOrderCRUD'
import {useDispatch} from 'react-redux'
import * as certificateorder from '../redux/CertificateOrderRedux'
import ListCertificateOrderData from './ListCertificateOrderData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callCertificateData = async () => {
    try {
      let certificateorderData = await getCertificateOrderData(0, 50)
      if (certificateorderData.data) {
        await dispatch(certificateorder.actions.setCertificateOrderLoading(true))
        // once certificateorder data set then loading will automatically off using saga middleware
        await dispatch(
          certificateorder.actions.setCertificateOrderData(certificateorderData.data.docs)
        )
        await dispatch(
          certificateorder.actions.setCertificateOrderDataTotal(certificateorderData.data.totalDocs)
        )
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callCertificateData()
  }, [])

  return (
    <div>
      <ListCertificateOrderData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'list',
            path: '/order/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'list',
            path: '/order/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.CERTIFICATEORDER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
