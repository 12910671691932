/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as certificateorder from '../redux/CertificateOrderRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EyeOutlined, DollarOutlined, EditOutlined, DeleteOutlined, CarOutlined} from '@ant-design/icons'
import {changeStatusForSingleCertificate, getCertificateOrderData, getSearchCertificateOrderData} from '../redux/CertificateOrderCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {vendorPaymentData} from '../../vendor/redux/VendorCRUD'
import {Select} from 'antd'
import {editCertificateOrderShipmentData} from '../redux/CertificateOrderCRUD'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  certificateOrderData: any
  certificateOrderDataTotal: number
  certificateOrderLoading: boolean
  role: any
  certificateOrderViewData: any
  setListCertificateOrderData: (certificateOrderData: any) => void
  setListCertificateOrderViewData: (certificateOrderViewData: any) => void
  setListCertificateOrderDataTotal: (certificateOrderDataTotal: number) => void
  setCertificateOrderLoading: (certificateOrderLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  certificateSelectedData: any
  certificateViewData: any
  dropDownValueState: any
}

type PathParamsType = {
  param1: string
}
class ViewCertificateTableData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      certificateSelectedData: null,
      certificateViewData: null,
      dropDownValueState: '',
    }
  }

  public onUpdateShipmentStatus = async () => {
    try {
      const {dropDownValueState} = this.state

      // if (this.state.dropDownValueState) {
      await editCertificateOrderShipmentData(
        dropDownValueState,
        this.props.certificateOrderViewData._id
      )

      Toaster({
        type: 'success',
        title: 'Certificate Order',
        description: 'Shipment Status Edited SuccessFully',
      })
      this.props.history.push('/order/list')
      // } else {
      //   Toaster({
      //     type: 'error',
      //     title: 'Shipment Status',
      //     description: 'Please Update Shipment Status',
      //   })
      //   return
      // }
    } catch (e) {
      console.log('!!!!Error', e)

      Toaster({
        type: 'error',
        title: 'Certificate Order',
        description: 'Failed To Update Shipment Status',
      })
      this.props.history.push('/order/list')
    } finally {
    }
  }

  public onUpdateSingleShipmentStatus = async (item: any) => {
    try {
      const {dropDownValueState} = this.state

      console.log(item,"id")
      // if (this.state.dropDownValueState) {
      await changeStatusForSingleCertificate(
        item._id
      )

      Toaster({
        type: 'success',
        title: 'Certificate Order',
        description: 'Shipment Status Edited SuccessFully',
      })
      this.props.history.push('/order/list')
      // } else {
      //   Toaster({
      //     type: 'error',
      //     title: 'Shipment Status',
      //     description: 'Please Update Shipment Status',
      //   })
      //   return
      // }
    } catch (e) {
      console.log('!!!!Error', e)

      Toaster({
        type: 'error',
        title: 'Certificate Order',
        description: 'Failed To Update Shipment Status',
      })
      this.props.history.push('/order/list')
    } finally {
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListCertificateOrderData(pagination)
    )
  }

  public getListCertificateOrderData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listCertificateOrderSearchData(start, end)
        } else {
          this.listCertificateOrderData(start, end)
        }
      }
    )
  }

  public listCertificateOrderData = async (start: number, end: any) => {
    const {setCertificateOrderLoading} = this.props
    try {
      const {setListCertificateOrderData, setListCertificateOrderDataTotal} = this.props
      await setCertificateOrderLoading(true)
      let certificateOrderData = await getCertificateOrderData(start, end)
      if (certificateOrderData.data) {
        await setListCertificateOrderData(certificateOrderData.data.docs)
        await setListCertificateOrderDataTotal(certificateOrderData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateOrderLoading(false)
    }
  }

  public listCertificateOrderSearchData = async (start: number, end: any) => {
    const {setCertificateOrderLoading} = this.props
    try {
      const {setListCertificateOrderData, setListCertificateOrderDataTotal} = this.props
      const {searchText} = this.state

      await setCertificateOrderLoading(true)
      let certificateOrderData = await getSearchCertificateOrderData(start, end, searchText)
      if (certificateOrderData.data) {
        await setListCertificateOrderData(certificateOrderData.data.docs)
        await setListCertificateOrderDataTotal(certificateOrderData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateOrderLoading(false)
    }
  }

  public onCreateVendor = () => {
    const {history} = this.props
    history.push('/order/create')
  }

  public showEditModal = async (item: any) => {
    this.setState({
      certificateSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      certificateSelectedData: null,
    })
  }

  public showViewModal = async (item: any) => {
    this.setState({
      certificateViewData: item,
    })
    this.props.setListCertificateOrderViewData(item)

    const {history} = this.props
    history.push('/order/view')
  }

  public closeViewModal = async () => {
    this.setState({
      certificateViewData: null,
    })
    this.listCertificateOrderData(this.state.startIndex, this.state.limitNumber)
  }

  public goBackTOCertificateOrderList = async () => {
    const {history} = this.props
    history.push('/order/list')
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Image',
      key: 'image_url',
      ellipsis: true,
      render: (text: any, item: any, index: number) => {
        return item.image_url ? (
          <a href={item.image_url ? item.image_url : ''} target='_blank'>
            {item.image_url ? item.image_url : 'No Image'}
          </a>
        ) : (
          <div style={{color: 'red', fontWeight: 'bold'}}>{'No Image'}</div>
        )
      },
    },
    {
      title: 'Shipment Status',
      key: 'shipment_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.shipment_status && item.shipment_status == 'approved' ? (
              <h2
                className='badge badge-pill bg-success'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Approved
              </h2>
            ) : item.shipment_status && item.shipment_status == 'rejected' ? (
              <h2
                className='badge badge-pill bg-danger'
                style={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                Rejected
              </h2>
            ) : (
              item.shipment_status &&
              item.shipment_status == 'pending' && (
                <h2
                  className='badge badge-pill bg-warning'
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  Pending
                </h2>
              )
            )}
          </div>
        )
      },
    },
    {
      title: 'Shape Cut',
      key: 'shape_cut',
      render: (text: any, item: any, index: number) => {
        return <div>{item.shape_cut ? item.shape_cut : '-'}</div>
      },
    },
    {
      title: 'Weight',
      key: 'weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.weight ? item.weight : '-'}</div>
      },
    },
    {
      title: 'Color',
      key: 'color',
      render: (text: any, item: any, index: number) => {
        return <div>{item.color ? item.color : '-'}</div>
      },
    },
    {
      title: 'DIA',
      key: 'dia',
      render: (text: any, item: any, index: number) => {
        return <div>{item.dia ? item.dia : '-'}</div>
      },
    },
    {
      title: 'Clarity',
      key: 'clarity',
      render: (text: any, item: any, index: number) => {
        return <div>{item.clarity ? item.clarity : '-'}</div>
      },
    },
    {
      title: 'Purity',
      key: 'purity',
      render: (text: any, item: any, index: number) => {
        return <div>{item.purity ? item.purity : '-'}</div>
      },
    },
    {
      title: 'Code',
      key: 'code',
      render: (text: any, item: any, index: number) => {
        return <div>{item.code ? item.code : '-'}</div>
      },
    },
    {
      title: 'Comments',
      key: 'comments',
      render: (text: any, item: any, index: number) => {
        return <div>{item.comments ? item.comments : '-'}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {this.props.role == 'super admin' &&
               this.props.certificateOrderViewData.payment_status == 'completed' &&
               this.props.certificateOrderViewData.shipment_status == 'pending' &&
               item.shipment_status != "shipped" && (
                <div style={{float: 'left', marginRight: '5px'}}>
                  <Tooltip placement='bottom' title='Confirm Shipment'>
                    <Popconfirm
                      onConfirm={() => {
                        //call delete vendor api
                        this.onUpdateSingleShipmentStatus(item)
                      }}
                      title='Are you sure you want to change shipment status to completed?'
                    >
                      <Button shape='circle' icon={<CarOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
              )}
          </div>
        )
      },
    },
  ]

  public render() {
    const {certificateOrderData, certificateOrderDataTotal, certificateOrderLoading} = this.props
    const {searchText, certificateSelectedData, certificateViewData} = this.state

    return (
      <div>
        {this.props.certificateOrderViewData ? (
          <div className='container'>
            <div className='card card-custom'>
              <div style={{display: 'flex',marginLeft:"20px",marginTop:"10px"}}>
              <div style={{margin: '12px'}}>
                  <InputButtonComponent
                    onClick={() => {
                      this.goBackTOCertificateOrderList()
                    }}
                  >
                    Back
                  </InputButtonComponent>
                </div>
                {this.props.role == 'super admin' &&
                  this.props.certificateOrderViewData.payment_status == 'completed' &&
                  this.props.certificateOrderViewData.shipment_status == 'pending' && (
                    <></>
                    // <div style={{margin: '12px'}}>
                    //   <Popconfirm
                    //     onConfirm={() => {
                    //       //call delete vendor api
                    //       this.onUpdateShipmentStatus()
                    //     }}
                    //     title='Are you sure you want to change shipment status as shipped?'
                    //   >
                    //     <InputButtonComponent
                    //       onClick={() => {
                    //         // this.onUpdateShipmentStatus()
                    //       }}
                    //     >
                    //       Update Shipment Status
                    //     </InputButtonComponent>
                    //   </Popconfirm>
                    // </div>
                    //   <>
                    //     <div style={{margin: '20px', display: 'flex'}}>
                    //       {/* <span style={{ fontSize: "16px", fontWeight: "bold", marginLeft: '15px', marginRight: '15px', display: "inline-block" }}>Select Market</span> */}
                    //       <Select
                    //         style={{
                    //           display: 'block',
                    //           borderRadius: '6px',
                    //           width: '200px',
                    //           marginRight: '15px',
                    //         }}
                    //         id='shipment_status'
                    //         value={
                    //           this.state.dropDownValueState ? this.state.dropDownValueState : undefined
                    //         }
                    //         onChange={(value: any) => {
                    //           // setFieldValue('filters', value)
                    //           this.setState({
                    //             dropDownValueState: value,
                    //           })
                    //         }}
                    //         placeholder='Select Shipment Status'
                    //       >
                    //         <Select.Option key={'pending'} value={'pending'}>
                    //           Pending
                    //         </Select.Option>
                    //         <Select.Option key={'shipped'} value={'shipped'}>
                    //           Shipped
                    //         </Select.Option>
                    //       </Select>
                    //       {/* <span style={{ fontSize: "16px", fontWeight: "bold", marginLeft: '15px', marginRight: '15px', display: "inline-block" }}>Select Market</span> */}
                    //       <InputButtonComponent
                    //         onClick={() => {
                    //           this.onUpdateShipmentStatus()
                    //         }}
                    //       >
                    //         Update
                    //       </InputButtonComponent>
                    //     </div>
                    //   </>
                  )} 
              </div>
              <div className='card-body'>
                <div style={{overflowX: 'auto'}}>
                  <ListTable
                    rowKey={(record: {_id: any}) => record._id}
                    onChange={this.handleTableChange}
                    column={this.columns}
                    paginationConfig={{
                      total: this.props.certificateOrderViewData.certificate_ids
                        ? this.props.certificateOrderViewData.certificate_ids.length
                          ? this.props.certificateOrderViewData.certificate_ids.length
                          : 0
                        : 0,
                      showSizeChanger: true,
                      pageSize: this.state.pagination.pageSize,
                      current: this.state.pagination.current,
                    }}
                    isLoading={certificateOrderLoading}
                    dataSource={
                      this.props.certificateOrderViewData
                        ? this.props.certificateOrderViewData.certificate_ids
                          ? this.props.certificateOrderViewData.certificate_ids
                          : []
                        : []
                    }
                    counter={this.counter}
                    tempCounter={this.tempCounter}
                    isScroll={true}
                    scrollWidth={1200}
                  />
                  <div>
                    <CommanModal
                      show={certificateSelectedData ? true : false}
                      handleClose={() => {
                        this.closeEditModal()
                      }}
                      title={'Edit Certificate'}
                      submitText={'Save Changes'}
                    >
                      <div>
                        {/* {certificateSelectedData && (
                        <EditCertificateForm
                          data={certificateSelectedData}
                          onSubmit={(values: any) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )} */}
                      </div>
                    </CommanModal>
                    {/* <CommanModal
                    show={certificateViewData ? true : false}
                    handleClose={() => {
                      this.closeViewModal()
                    }}
                    title={'View Certificate'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {/* {certificateViewData && (
                        <ViewCertificateData
                          data={certificateViewData}
                          // onSubmit={(values: any) => {
                          //   this.onEditSubmit(values)
                          // }}
                          handleCancel={() => {
                            this.closeViewModal()
                          }}
                        />
                      )} */}
                    {/* </div> */}
                    {/* </CommanModal> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{color: 'red', margin: 'auto 40%', fontSize: '16px', fontWeight: 'bold'}}>
            NO DATA FOUND
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  certificateOrderData: state.certificateorder.certificateOrderData,
  certificateOrderDataTotal: state.certificateorder.certificateOrderDataTotal,
  certificateOrderLoading: state.certificateorder.certificateOrderLoading,
  role: state.auth.role,
  certificateOrderViewData: state.certificateorder.certificateOrderViewData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListCertificateOrderData: (certificateOrderData: any) => {
      dispatch(certificateorder.actions.setCertificateOrderData(certificateOrderData))
    },
    setListCertificateOrderDataTotal: (certificateOrderDataTotal: number) => {
      dispatch(certificateorder.actions.setCertificateOrderDataTotal(certificateOrderDataTotal))
    },
    setCertificateOrderLoading: (certificateOrderLoading: boolean) => {
      dispatch(certificateorder.actions.setCertificateOrderLoading(certificateOrderLoading))
    },
    setListCertificateOrderViewData: (certificateOrderViewData: any) => {
      dispatch(certificateorder.actions.setCertificateOrderViewData(certificateOrderViewData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewCertificateTableData))
