/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CertificateOrderPage} from '../../component/certificateorder'

const CertificateOrderWrapper: FC = () => {
  return (
    <div>
      <CertificateOrderPage />
    </div>
  )
}

export {CertificateOrderWrapper}
