/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CreateWrapper} from './containers/CreateCertificate'
import {ListWraaper} from './containers/List'
import ViewCertificateData from './containers/ViewCertificateData'

export function CertificatePage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/certificate/list' exact={true} component={ListWraaper} />
        <Route path='/certificate/create' exact={true} component={CreateWrapper} />
        <Route path='/certificate/view' exact={true} component={ViewCertificateData} />
        <Redirect from='/certificate' exact={true} to='/certificate/list' />
        <Redirect to='/certificate/list' />
      </Switch>
    </div>
  )
}
