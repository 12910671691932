import * as React from 'react'
import {Formik} from 'formik'
import {Button} from 'antd'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Spin} from 'antd'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {zipBulkImageUpload} from '../redux/CertificateCRUD'

const API_URL = process.env.REACT_APP_API_URL
interface IRenderFormikForm {
  values: IBulkFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IBulkFormValues {
  zip: any
}

interface IEditClientProps {
  data?: any
  handleCancel?: any
}

let size: any
let valuesData: any
let WrongValuesArray: any = []

// const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class BulkUploadImageZip extends React.Component<IEditClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: null,
      progress: 0,
      url: '',
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IBulkFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)
    if (!values.zip) {
      errors.zip = 'Zip is Required'
    }
    console.log('Validating errors -- ', errors)
    size = Object.keys(errors).length
    return errors
  }

  public handleSubmit = (values: IBulkFormValues, action: any) => {}

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public uploadMedia = async (fileName: string, FileData: Array<any>, customFileData: Object) => {
    try {
      if (fileName && FileData.length >= 1) {
        this.setState({
          uploadMedia: FileData,
        })
        console.log('!!!!!!!!!!!uploadMedia')
      }
      //close modal popup
      this.setState({
        viewFileUploadModal: false,
        isImageLinked: false,
        //   image_url: `${REACT_APP_API_URL}certificate/image/${fileName}`,
        file_name: fileName,
        isLoading: true
      })
      let imageZipUpload = await zipBulkImageUpload(fileName)

      Toaster({
        type: 'success',
        title: 'Bulk Upload',
        description: 'File uploaded successFully',
      })
      this.props.handleCancel()
    } catch (e) {
      console.log(e)
      Toaster({
        type: 'error',
        title: 'Bulk Upload',
        description: 'File uploaded Failed',
      })
    } finally {
      this.setState({
        isLoading: false
      })
      this.props.handleCancel()
    }
  }

  public showMediaModal = (params: any) => {
    this.setState(
      {
        viewFileUploadModal: true,
      },
      () => {
        this.viewFileUploadModalRef?.show?.()
      }
    )
  }

  public render() {
    return (
      <div className='container'>
        <div className='card card-custom'>
          {this.props.data && (
            <div className='card-body'>
              <div>
                <Formik
                  initialValues={{
                    zip: '',
                  }}
                  validate={this.validateForm}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                  }: IRenderFormikForm) => {
                    return (
                      <div className='table-form'>
                        <Spin spinning={this.state.isLoading} tip='Uploading...'>
                          <div className='form-group row'>
                            {/* <div className='col-md-12 col-lg-12 col-sm-12'>
                            <form
                              onSubmit={(e: any) => {
                                this.formHandler(e)
                              }}
                            >
                              <input
                                type='file'
                                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                              ></input>
                              <button
                                type='submit'
                                style={{
                                  backgroundColor: '#1890ff',
                                  color: 'white',
                                  borderRadius: '6px',
                                  borderColor: 'white',
                                  padding: '5px',
                                }}
                              >
                                Upload
                              </button>
                            </form>
                          </div> */}
                            <InputSingleFileUpload
                              ref={(e: any) => {
                                this.viewFileUploadModalRef = e
                              }}
                              //   buttonLabel={'Choose Provider Image'}
                              onBlur={() => {}}
                              error={() => {}}
                              touched={() => {}}
                              //   required={false}
                              //   label='Item Image'
                              name='bulk'
                              input={{
                                id: 'images',
                                name: 'images',
                                action: `${REACT_APP_API_URL}file/upload/certificate/images`,
                                headers: {
                                  authorization: 'authorized-text',
                                },
                                accept: 'zip',
                                acceptMimeTypes: [
                                  'application/zip',
                                  'application/x-rar-compressed',
                                  'application/x-zip-compressed',
                                ],
                                mediaType: 'zip',
                                mediaModuleType: 'zip',
                              }}
                              onUpload={(
                                fileName: string,
                                FileData: Array<any>,
                                customFileData: Object
                              ) => {
                                this.uploadMedia(fileName, FileData, customFileData)
                              }}
                              showUploadButton={false}
                            />
                            <div className='col-md-12 col-lg-12 col-sm-12 text-primary pt-4 fs-6'>
                              Upload zip file
                            </div>
                            <div
                              className='col-md-12 col-lg-12 col-sm-12'
                              style={{color: '#f1416c'}}
                            >
                              {errors.zip}
                            </div>
                          </div>
                        </Spin>
                        {/* <div className='form-group row'>
                          <div className='col-md-5 col-lg-5 col-sm-5'>
                            <Button
                              type='primary'
                              disabled={size}
                              style={{marginTop: '15px', float: 'left', borderRadius: '6px'}}
                              onClick={(e: any) => {
                                e.preventDefault()
                                window.open('www.google.com', '_blank')
                              }}
                            >
                              Download Sample Zip
                            </Button>
                          </div>
                        </div> */}
                      </div>
                    )
                  }}
                </Formik>
              </div>
            </div>
          )}
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
