import React, {Component} from 'react'
import {getSingleCertificateData} from '../redux/CertificateCRUD'
import {connect} from 'react-redux'
import * as certificate from '../redux/CertificateRedux'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {History, LocationState} from 'history'

interface IViewProps {
  data?: any
  handleCancel?: any
  loginUserData?: any
}

interface IRecordsTableProps {
  certificateData: any
  certificateDataTotal: number
  certificateLoading: boolean
  certificateId: string
  role: any
  setListCertificateData: (certificateData: any) => void
  setListCertificateDataTotal: (certificateDataTotal: number) => void
  setCertificateLoading: (certificateLoading: boolean) => void
  setCertificateId: (certificateId: any) => void
  history?: History<LocationState>
}
interface IRecordsTableState {
  certificateDataState: any
  isLoading: boolean
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
class ViewCertificateData extends React.Component<
  RouteComponentProps<any> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      certificateDataState: {},
      isLoading: false,
    }
  }

  componentDidMount() {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      let certificateData = await getSingleCertificateData(
        this.props.certificateId,
        this.props.role
      )
      if (certificateData.data) {
        this.setState({certificateDataState: certificateData.data})
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  render() {
    {
      console.log(this.state.certificateDataState)
    }
    return (
      <div className='card card-custom'>
        {this.state.certificateDataState ? (
          <>
            {/*
            <div className='group row'>
              <label
                className='col-sm-2 col-label'
                style={{
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  marginRight: '5px',
                  fontSize: '18px',
                }}
              >
                Shape Cut :{' '}
              </label>
              <div className='col-sm-4'>
                <span style={{fontSize: '15px'}}>
                  {this.state.certificateDataState.shape_cut
                    ? this.state.certificateDataState.shape_cut
                    : ''}
                </span>
              </div>

              <label
                className='col-sm-2 col-label'
                style={{
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  marginRight: '5px',
                  fontSize: '18px',
                }}
              >
                Clarity :{' '}
              </label>
              <div className='col-sm-4'>
                <span style={{fontSize: '15px'}}>
                  {this.state.certificateDataState.clarity
                    ? this.state.certificateDataState.clarity
                    : ''}
                </span>
              </div>

              {/* <label
                className='col-sm-2 col-label'
                style={{fontWeight: 'bold', marginLeft: '5px', marginRight: '5px'}}
              >
                Weight :{' '}
              </label>
              <div className='col-sm-2'>
                <span style={{fontSize: '15px'}}>
                  {this.state.certificateDataState.weight
                    ? this.state.certificateDataState.weight
                    : ''}
                </span>
              </div> */}
            {/* </div> */}
            {/* */}
            <div className='row justify-content-center' style={{marginBottom: '20px'}}>
              <div className='col align-self-center'>
                <img
                  src={`${this.state.certificateDataState.image_url}`}
                  alt='Certificate Image'
                  style={{
                    width: '450px',
                    height: '200px',
                    margin: 'auto',
                    marginTop: '25px',
                    // marginLeft: '25%',
                    marginBottom: '25px',
                    textAlign: 'center',
                    display: 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </div>
            </div>

            <div className='row' style={{marginBottom: '20px'}}>
              <div className='col'>
                <span
                  style={{
                    margin: '5px',
                    marginLeft: '20px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                  }}
                >
                  Certificate Number :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.certificate_number
                    ? this.state.certificateDataState.certificate_number
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span style={{margin: '5px', fontSize: '16px', fontWeight: 'bolder'}}>
                  Shape Cut :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.shape_cut
                    ? this.state.certificateDataState.shape_cut
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span style={{margin: '5px', fontSize: '16px', fontWeight: 'bolder'}}>
                  Clarity :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.clarity
                    ? this.state.certificateDataState.clarity
                    : '-'}
                </span>
              </div>
            </div>

            <div className='row' style={{marginBottom: '20px'}}>
              <div className='col'>
                <span
                  style={{
                    margin: '5px',
                    marginLeft: '20px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                  }}
                >
                  Color :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.color
                    ? this.state.certificateDataState.color
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span style={{margin: '5px', fontSize: '16px', fontWeight: 'bolder'}}>
                  Purity :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.purity
                    ? this.state.certificateDataState.purity
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span style={{margin: '5px', fontSize: '16px', fontWeight: 'bolder'}}>
                  Gross Weight :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.gross_weight
                    ? this.state.certificateDataState.gross_weight
                    : '-'}
                </span>
              </div>
            </div>

            <div className='row' style={{marginBottom: '20px'}}>
              <div className='col'>
                <span
                  style={{
                    margin: '5px',
                    marginLeft: '20px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                  }}
                >
                  Diamond Weight :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.diamond_weight
                    ? this.state.certificateDataState.diamond_weight
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span
                  style={{
                    margin: '5px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                  }}
                >
                  Category :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.category
                    ? this.state.certificateDataState.category
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span style={{margin: '5px', fontSize: '16px', fontWeight: 'bolder'}}>DIA : </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.dia ? this.state.certificateDataState.dia : '-'}
                </span>
              </div>
            </div>

            <div className='row' style={{marginBottom: '20px'}}>
              <div className='col'>
                <span
                  style={{
                    margin: '5px',
                    marginLeft: '20px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                  }}
                >
                  Code :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.code
                    ? this.state.certificateDataState.code
                    : '-'}
                </span>
              </div>
              <div className='col d-flex justify-content-between'>
                <span
                  style={{
                    margin: '5px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Comments :
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.comments
                    ? this.state.certificateDataState.comments
                    : '-'}
                </span>
              </div>

              <div className='col'>
                <span style={{margin: '5px', fontSize: '16px', fontWeight: 'bolder'}}>
                  Description :{' '}
                </span>
                <span
                  className='text-muted'
                  style={{margin: '5px', fontSize: '14px', fontWeight: 'bold'}}
                >
                  {this.state.certificateDataState.description
                    ? this.state.certificateDataState.description
                    : '-'}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border'></div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  certificateData: state.certificate.certificateData,
  certificateDataTotal: state.certificate.certificateDataTotal,
  certificateLoading: state.certificate.certificateLoading,
  certificateId: state.certificate.certificateId,
  role: state.auth.role,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListCertificateData: (certificateData: any) => {
      dispatch(certificate.actions.setCertificateData(certificateData))
    },
    setListCertificateDataTotal: (certificateDataTotal: number) => {
      dispatch(certificate.actions.setCertificateDataTotal(certificateDataTotal))
    },
    setCertificateLoading: (certificateLoading: boolean) => {
      dispatch(certificate.actions.setCertificateLoading(certificateLoading))
    },
    setCertificateId: (certificateId: string) => {
      dispatch(certificate.actions.setCertificateId(certificateId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewCertificateData))
