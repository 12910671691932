/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as certificate from '../redux/CertificateRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EyeOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeStatusCertificateData,
  deleteCertificateData,
  editCertificateData,
  getCertificateData,
  getSearchCertificateData,
} from '../redux/CertificateCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {EditCertificateForm} from './EditCertificateForm'
import {BulkUploadCertificate} from './BulkUploadCertificate'
import {BulkUploadImageZip} from './BulkUploadImageZip'
// import {ViewCertificateData} from './ViewCertificateData'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  certificateData: any
  certificateDataTotal: number
  certificateLoading: boolean
  role: any
  authToken: string
  setListCertificateData: (certificateData: any) => void
  setListCertificateDataTotal: (certificateDataTotal: number) => void
  setCertificateLoading: (certificateLoading: boolean) => void
  setCertificateId: (certificateId: any) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  certificateSelectedData: any
  certificateViewData: any
  bulkUploadModalState: boolean
  bulkUploadImageModalState: boolean
}

type PathParamsType = {
  param1: string
}
class ListCertificateData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 50,
      pagination: {
        pageSize: 50,
        current: 1,
      },
      searchText: '',
      certificateSelectedData: null,
      certificateViewData: null,
      bulkUploadModalState: false,
      bulkUploadImageModalState: false,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListCertificateData(pagination)
    )
  }

  public getListCertificateData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listCertificateSearchData(start, end)
        } else {
          this.listCertificateData(start, end)
        }
      }
    )
  }

  public listCertificateData = async (start: number, end: any) => {
    const {setCertificateLoading} = this.props
    try {
      const {setListCertificateData, setListCertificateDataTotal} = this.props
      await setCertificateLoading(true)
      let certificateData = await getCertificateData(start, end, this.props.role)
      if (certificateData.data) {
        await setListCertificateData(certificateData.data.docs)
        await setListCertificateDataTotal(certificateData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateLoading(false)
    }
  }

  public listCertificateSearchData = async (start: number, end: any) => {
    const {setCertificateLoading} = this.props
    try {
      const {setListCertificateData, setListCertificateDataTotal} = this.props
      const {searchText} = this.state

      await setCertificateLoading(true)
      let certificateData = await getSearchCertificateData(start, end, searchText, this.props.role)
      if (certificateData.data) {
        await setListCertificateData(certificateData.data.docs)
        await setListCertificateDataTotal(certificateData.data.totalDocs)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listCertificateSearchData(0, 50)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateVendor = () => {
    const {history} = this.props
    history.push('/certificate/create')
  }

  public onBulkUpload = () => {
    this.setState({
      bulkUploadModalState: true,
    })
  }

  public onCloseBulkUploadModal = async () => {
    this.setState({
      bulkUploadModalState: false,
    })
    this.listCertificateData(this.state.startIndex, this.state.limitNumber)
  }

  public onBulkUploadImage = () => {
    this.setState({
      bulkUploadImageModalState: true,
    })
  }

  public onCloseBulkUploadImageModal = async () => {
    this.setState({
      bulkUploadImageModalState: false,
    })
    this.listCertificateData(this.state.startIndex, this.state.limitNumber)
  }

  public deleteCertificate = async (item: any) => {
    const {setCertificateLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCertificateLoading(true)
      await deleteCertificateData(item._id, this.props.role)

      Toaster({
        type: 'success',
        title: 'Certificate',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listCertificateSearchData(startIndex, limitNumber)
        return
      }
      this.listCertificateData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setCertificateLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCertificateLoading(true)
      await changeStatusCertificateData(item._id, checked, this.props.role)

      Toaster({
        type: 'success',
        title: 'Certificate',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listCertificateSearchData(startIndex, limitNumber)
        return
      }
      this.listCertificateData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateLoading(false)
    }
  }

  public onEditSubmit = async (values: any) => {
    const {setCertificateLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setCertificateLoading(true)
      await editCertificateData(values, this.props.role)

      Toaster({
        type: 'success',
        title: 'Certificate',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listCertificateSearchData(startIndex, limitNumber)
        return
      }
      this.listCertificateData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setCertificateLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      certificateSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      certificateSelectedData: null,
    })
    this.listCertificateData(this.state.startIndex, this.state.limitNumber)
  }

  public showViewModal = async (item: any) => {
    this.props.setCertificateId(item._id)

    const {history} = this.props
    history.push('/certificate/view')

    // this.setState({
    //   certificateViewData: item,
    // })
  }

  public closeViewModal = async () => {
    this.setState({
      certificateViewData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    // {
    //   title: 'Certificate Name',
    //   key: 'Certificate Name',
    //   fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <strong>
    //           {item.first_name && item.last_name
    //             ? `${item.first_name} ${item.last_name}`
    //             : item.first_name
    //             ? item.first_name
    //             : '-'}
    //         </strong>
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Certificate Number',
      key: 'certificate_number',
      render: (text: any, item: any, index: number) => {
        return <div>{item.certificate_number ? item.certificate_number : '-'}</div>
      },
    },
    {
      title: 'Shape Cut',
      key: 'shape_cut',
      render: (text: any, item: any, index: number) => {
        return <div>{item.shape_cut ? item.shape_cut : '-'}</div>
      },
    },
    // {
    //   title: 'Weight',
    //   key: 'weight',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.weight ? item.weight : '-'}</div>
    //   },
    // },
    {
      title: 'Gross Weight',
      key: 'gross_weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.gross_weight ? item.gross_weight : '-'}</div>
      },
    },
    {
      title: 'Diamond Weight',
      key: 'diamond_weight',
      render: (text: any, item: any, index: number) => {
        return <div>{item.diamond_weight ? item.diamond_weight : '-'}</div>
      },
    },
    {
      title: 'Color',
      key: 'color',
      render: (text: any, item: any, index: number) => {
        return <div>{item.color ? item.color : '-'}</div>
      },
    },
    {
      title: 'Clarity',
      key: 'clarity',
      render: (text: any, item: any, index: number) => {
        return <div>{item.clarity ? item.clarity : '-'}</div>
      },
    },
    // {
    //   title: 'isActive',
    //   key: 'isActive',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_active != null ? (
    //           <Tooltip placement='bottom' title='Vendor Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_active}
    //                 style={{
    //                   backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='View'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showViewModal(item)
                  }}
                />
              </Tooltip>
            </div>
            {/* <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete certificate api
                    this.deleteCertificate(item)
                  }}
                  title='Are you sure you want to delete certificate?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div> */}
          </div>
        )
      },
    },
  ]

  public render() {
    const {certificateData, certificateDataTotal, certificateLoading} = this.props
    const {searchText, certificateSelectedData, certificateViewData} = this.state

    return (
      <div>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Certificate'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onBulkUpload()
                  }}
                >
                  Bulk Upload Certificate
                </InputButtonComponent>
                <span style={{marginRight: '15px'}}></span>
                <InputButtonComponent
                  onClick={() => {
                    this.onBulkUploadImage()
                  }}
                >
                  Bulk Upload Image
                </InputButtonComponent>
                <span style={{marginRight: '15px'}}></span>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateVendor()
                  }}
                >
                  Add Certificate
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  paginationConfig={{
                    total: certificateDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={certificateLoading}
                  dataSource={certificateData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                  scrollWidth={1200}
                />
                <div>
                  <CommanModal
                    show={certificateSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Certificate'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {certificateSelectedData && (
                        <EditCertificateForm
                          data={certificateSelectedData}
                          onSubmit={(values: any) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>

                  <CommanModal
                    show={this.state.bulkUploadModalState ? true : false}
                    handleClose={() => {
                      this.onCloseBulkUploadModal()
                    }}
                    title={'Bulk Upload Certificate'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {this.state.bulkUploadModalState && (
                        <BulkUploadCertificate
                          data={this.state.bulkUploadModalState}
                          handleCancel={() => {
                            this.onCloseBulkUploadModal()
                          }}
                          authToken={this.props.authToken}
                        />
                      )}
                    </div>
                  </CommanModal>

                  <CommanModal
                    show={this.state.bulkUploadImageModalState ? true : false}
                    handleClose={() => {
                      this.onCloseBulkUploadImageModal()
                    }}
                    title={'Bulk Upload Image'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {this.state.bulkUploadImageModalState && (
                        <BulkUploadImageZip
                          data={this.state.bulkUploadImageModalState}
                          handleCancel={() => {
                            this.onCloseBulkUploadImageModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  {/* <CommanModal
                    show={certificateViewData ? true : false}
                    handleClose={() => {
                      this.closeViewModal()
                    }}
                    title={'View Certificate'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {certificateViewData && (
                        <ViewCertificateData
                          data={certificateViewData}
                          // onSubmit={(values: any) => {
                          //   this.onEditSubmit(values)
                          // }}
                          handleCancel={() => {
                            this.closeViewModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  certificateData: state.certificate.certificateData,
  certificateDataTotal: state.certificate.certificateDataTotal,
  certificateLoading: state.certificate.certificateLoading,
  role: state.auth.role,
  authToken: state.auth.accessToken,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListCertificateData: (certificateData: any) => {
      dispatch(certificate.actions.setCertificateData(certificateData))
    },
    setListCertificateDataTotal: (certificateDataTotal: number) => {
      dispatch(certificate.actions.setCertificateDataTotal(certificateDataTotal))
    },
    setCertificateLoading: (certificateLoading: boolean) => {
      dispatch(certificate.actions.setCertificateLoading(certificateLoading))
    },
    setCertificateId: (certificateId: string) => {
      dispatch(certificate.actions.setCertificateId(certificateId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListCertificateData))
