/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {SettingWrapper} from './containers/Setting'

export function SettingPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/setting' exact={true} component={SettingWrapper} />
        <Redirect to='/setting' />
      </Switch>
    </div>
  )
}
