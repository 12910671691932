/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
// import {CreateWrapper} from './containers/CreateCertificate'
import {ListWraaper} from './containers/List'
import ViewCertificateOrderData from './containers/ViewCertificateOrderData'
import ViewCertificateTableData from './containers/ViewCertificateTableData'

export function CertificateOrderPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/order/list' exact={true} component={ListWraaper} />
        {/* <Route
          path='/order/create'
          exact={true}
         component={CreateWrapper}
        /> */}
        <Route path='/order/view' exact={true} component={ViewCertificateTableData} />
        <Redirect from='/order' exact={true} to='/order/list' />
        <Redirect to='/order/list' />
      </Switch>
    </div>
  )
}
