import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}v1/auth/get-admin`
export const LOGIN_URL = `${API_URL}v1/both/login/`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_URL}v1/auth/admin/change-password/`
export const REQUEST_CHANGE_EMAIL_URL = `${API_URL}v1/auth/admin/`

// Server should return AuthModel
export async function login(email: string, password: string) {
  try {
    let loginData: any = await axios.post(LOGIN_URL, {email, password})
    return {
      data: loginData.data,
      authToken: loginData.authToken,
    }
  } catch (e) {
    throw e
  }
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

// Server will change password
export async function changePassword(
  password: string,
  confirm_password: string,
  old_password: string
) {
  try {
    let changePasswordData = await axios.put(REQUEST_CHANGE_PASSWORD_URL, {
      oldPassword: old_password,
      newPassword: confirm_password,
    })
    return changePasswordData.data
  } catch (e) {
    throw e
  }
}

export async function changeEmailApi(email: string) {
  try {
    let changePasswordData = await axios.put(REQUEST_CHANGE_EMAIL_URL, {
      email,
    })
    return changePasswordData.data
  } catch (e) {
    throw e
  }
}
