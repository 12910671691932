/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAllKpisData} from '../redux/DashboardCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as dashboard from '../redux/DashboardRedux'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'
import {RootState} from '../../../../setup'

const DashboardPage: FC = () => {
  const dispatch = useDispatch()
  const dashboardDataStore = useSelector((state: any) => state.dashboard.dashboardData)
  const dashboardLoadingStore = useSelector((state: any) => state.dashboard.dashboardLoading)
  const isAuthorized = useSelector<RootState, any>(({auth}) => auth.user, shallowEqual)

  const callInitDashboardData = async () => {
    try {
      let dashboardData = await getAllKpisData()
      if (dashboardData.data) {
        await dispatch(dashboard.actions.setDashboardLoading(true))
        // once dashboard data set then loading will automatically off using saga middleware
        await dispatch(dashboard.actions.setDashboardData(dashboardData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitDashboardData()
  }, [])

  return (
    <div>
      {dashboardLoadingStore ? (
        <div></div>
      ) : (
        <div className='row g-5 g-xl-8'>
          {/* <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Clients'
            value={
              dashboardDataStore && String(dashboardDataStore.clientCount)
                ? String(dashboardDataStore.clientCount)
                : '0'
            }
            path='/client/list'
            background='white'
          /> */}

          {isAuthorized.role == 'super admin' && (
            <KpiCardComponent
              icon='/media/icons/duotune/art/art002.svg'
              title='Total Vendor'
              value={
                dashboardDataStore && String(dashboardDataStore.vendorCount)
                  ? String(dashboardDataStore.vendorCount)
                  : '0'
              }
              path='/vendor/list'
              background='white'
            />
          )}
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Certificate'
            value={
              dashboardDataStore && String(dashboardDataStore.certificateCount)
                ? String(dashboardDataStore.certificateCount)
                : '0'
            }
            path='/certificate/list'
            background='white'
          />

          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='Total Certificate Payment'
            value={
              dashboardDataStore && String(dashboardDataStore.certificatePaymentCount)
                ? String(dashboardDataStore.certificatePaymentCount)
                : '0'
            }
            path='/certificate/list'
            background='white'
          />
          {/* <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='New Customers'
            value='+3000'
            path='/client/list'
            background='black'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='New Customers'
            value='+3000'
            path='/client/list'
            background='yellow'
          />
          <KpiCardComponent
            icon='/media/icons/duotune/art/art002.svg'
            title='New Customers'
            value='+3000'
            path='/client/list'
            background='blue'
          /> */}
        </div>
      )}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
